import { HttpResponse } from '@angular/common/http';
import {
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import {
  FileSystemFileEntry,
  NgxFileDropEntry,
} from 'ngx-file-drop';
import { NGXLogger } from 'ngx-logger';
import { firstValueFrom } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';
import {
  ExamEventService,
  ExamService,
  NotificationService,
  RegistrationsSearchFilters,
  StatisticsSearchFilters,
} from '../../../services';
import {
  Exam,
  ExamEvent,
  FormValidationErrorResponse,
  SelectOption,
  TestResultsUploadInfo,
} from '../../../types';
import { ExamEventDetailsDialogComponent } from '../../dialogs';
import { SelectedEventInfos } from '../../tables';

@Component({
  selector: 'app-exam-form',
  templateUrl: './exam-form.component.html',
  styleUrls: ['./exam-form.component.scss'],
})
export class ExamFormComponent implements OnInit {
  @HostBinding('class.form-component')
  hostClass = true;

  isEditMode = false;

  currentlyUploadingResults = false;

  selectedExamEvent?: ExamEvent;

  private eventsSelectedForMultiAction!: SelectedEventInfos;

  private form!: FormGroup;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private dialog: MatDialog,
    private examEventService: ExamEventService,
    private examService: ExamService,
    private formBuilder: FormBuilder,
    private logger: NGXLogger,
    private note: NotificationService,
    private notificationService: NotificationService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [this.item.testname, [Validators.required]],
      templateName: [this.item.templateName],
      validityStart: [
        this.item.validityPeriodStart,
        [Validators.required],
      ],
      validityEnd: [this.item.validityPeriodEnd],
      testReportPdfTemplateIdControl: [
        this.item.resultReportTemplateId,
        [Validators.required],
      ],
      invitationPdfTemplateIdControl: [
        this.item.invitationTemplateId,
        [Validators.required],
      ],
    });

    this.eventsSelectedForMultiAction = {
      selectedEventIds: [],
      events: [],
    };

    this.breadcrumbService.set(
      '@examName',
      this.item.testname
        ? this.item.testname
        : { skip: true }
    );

    this.nameControl.valueChanges.subscribe((examName) => {
      if (examName) {
        this.breadcrumbService.set('@examName', {
          label: examName,
          skip: false,
        });
      }
    });

    this.isEditMode = !this.item.testname;
  }

  get item(): Exam {
    return this.examService.formState.item;
  }

  get nameControl(): FormControl<string | null> {
    return this.form.get('name') as FormControl<
      string | null
    >;
  }

  get templateControl(): FormControl<string | null> {
    return this.form.get('templateName') as FormControl<
      string | null
    >;
  }

  get templateOptions(): SelectOption[] {
    return this.examService.templateSelectOptions.sort(
      ({ label: label1 }, { label: label2 }) => {
        return label1.localeCompare(label2);
      }
    );
  }

  get validityStartControl(): FormControl<string | null> {
    return this.form.get('validityStart') as FormControl<
      string | null
    >;
  }

  get validityEndControl(): FormControl<string | null> {
    return this.form.get('validityEnd') as FormControl<
      string | null
    >;
  }

  get earliestEndDate(): Date | undefined {
    if (!this.validityStartControl.value) {
      return undefined;
    }
    return new Date(this.validityStartControl.value);
  }

  get testReportPdfTemplateIdControl(): FormControl<
    string | null
  > {
    return this.form.get(
      'testReportPdfTemplateIdControl'
    ) as FormControl<string | null>;
  }

  get invitationPdfTemplateIdControl(): FormControl<
    string | null
  > {
    return this.form.get(
      'invitationPdfTemplateIdControl'
    ) as FormControl<string | null>;
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.item.testname = this.nameControl.value || '';
      this.item.templateName =
        this.templateControl.value || '';
      this.item.validityPeriodStart =
        this.validityStartControl.value || '';
      this.item.validityPeriodEnd =
        this.validityEndControl.value || '';
      this.item.invitationTemplateId =
        this.invitationPdfTemplateIdControl.value || '';
      this.item.resultReportTemplateId =
        this.testReportPdfTemplateIdControl.value || '';

      this.examService
        .saveExam()
        .then(() => this.toggleEditMode())
        .catch((e: FormValidationErrorResponse) =>
          this.fail(e)
        );
    } else {
      this.logger.error('Form is not valid');
    }
  }

  toggleEditMode(): void {
    this.isEditMode = !this.isEditMode;
  }

  delete() {
    this.note
      .confirm({ message: 'label.confirm-delete' })
      .then((result) => {
        if (result) {
          this.examService
            .deleteExam(this.item.id)
            .then(async () => {
              await this.router.navigate(['/home/exam']);
            });
        }
      });
  }

  private async fail(errors: FormValidationErrorResponse) {
    if (errors && errors.forEach) {
      errors.forEach((error) => {
        const field = this.form.get(
          error.field
        ) as FormControl<unknown>;
        field.setErrors({ custom: error.message });
      });
    }

    const successMessage = await firstValueFrom(
      this.translateService.get(
        'message.test.save-failed.text'
      )
    );
    const successTitle = await firstValueFrom(
      this.translateService.get(
        'message.test.save-failed.title'
      )
    );

    await this.notificationService.error(
      successMessage,
      successTitle
    );
  }

  get eventStatisticsSearchFilters(): StatisticsSearchFilters {
    return {
      testIds: [this.item.id],
    };
  }

  get registrationsSearchFilters(): RegistrationsSearchFilters {
    return {
      testId: this.item.id,
    };
  }

  async testEventSelected(testEventId: number) {
    this.selectedExamEvent =
      await this.examEventService.loadByEventId(
        testEventId
      );

    this.dialog.open(ExamEventDetailsDialogComponent, {
      data: { examEvent: this.selectedExamEvent },
      minWidth: '300px',
      minHeight: '60vh',
      panelClass: 'position-relative',
    });
  }

  updateEventsSelectedForMultiAction(
    selectedEvents: SelectedEventInfos
  ): void {
    this.eventsSelectedForMultiAction = selectedEvents;
  }

  private fileNameFromContentDispositionHeader(
    response: HttpResponse<any>,
    defaultPrefix: string
  ): string {
    const contentDispositionHeader: string =
      response.headers.get('content-disposition') || '';
    const matches = /filename="(?<filename>.*)"/.exec(
      contentDispositionHeader
    );

    return (
      matches?.groups?.filename ||
      `${defaultPrefix}_${DateTime.now().toFormat(
        'yyyyMMdd'
      )}.csv`
    );
  }

  async downloadRegistrations(): Promise<void> {
    try {
      const response =
        await this.examService.downloadRegistrationsList(
          this.registrationsSearchFilters
        );

      if (!response.body) {
        throw new Error('No body present in csv download');
      }

      const fileName =
        this.fileNameFromContentDispositionHeader(
          response,
          'test-registrations'
        );

      // Adding a UTF-8 BOM, as explained in https://stackoverflow.com/a/41363077/2382246
      // saveAs does allow setting "autoBom: true" in the options. However, this doesn't seem to work in this case, so we add the BOM ourselves.
      const blob = new Blob(
        [new Uint8Array([0xef, 0xbb, 0xbf]), response.body],
        { type: response.body?.type }
      );

      saveAs(blob, fileName);
    } catch (error) {
      this.logger.error(error);
      await this.notificationService.error(
        'error.csv-download-failed'
      );
    }
  }

  async downloadStatistics(): Promise<void> {
    try {
      const response =
        await this.examEventService.downloadStatisticsList(
          this.eventStatisticsSearchFilters
        );

      if (!response.body) {
        throw new Error('No body present in csv download');
      }

      const fileName =
        this.fileNameFromContentDispositionHeader(
          response,
          'test-events'
        );

      // Adding a UTF-8 BOM, as explained in https://stackoverflow.com/a/41363077/2382246
      // saveAs does allow setting "autoBom: true" in the options. However, this doesn't seem to work in this case, so we add the BOM ourselves.
      const blob = new Blob(
        [new Uint8Array([0xef, 0xbb, 0xbf]), response.body],
        { type: response.body?.type }
      );

      saveAs(blob, fileName);
    } catch (error) {
      this.logger.error(error);
      await this.notificationService.error(
        'error.csv-download-failed'
      );
    }
  }

  get areTestEventsSelected(): boolean {
    return (
      this.eventsSelectedForMultiAction.selectedEventIds
        .length > 0
    );
  }

  get isSendBookingsDisabled(): boolean {
    if (
      this.eventsSelectedForMultiAction.selectedEventIds
        .length === 0
    ) {
      return true;
    }
    const selectedEventsWithBookings =
      this.eventsSelectedForMultiAction.events
        .filter(
          (event) =>
            this.eventsSelectedForMultiAction.selectedEventIds.indexOf(
              event.testEventId
            ) >= 0
        )
        .filter(
          ({ numberOfBookings }) => numberOfBookings > 0
        )
        .map(({ testEventId }) => testEventId);
    return selectedEventsWithBookings.length === 0;
  }

  async sendInvitationsForSelectedEvents(): Promise<void> {
    const selectedEventsWithBookings =
      this.eventsSelectedForMultiAction.events
        .filter(
          (event) =>
            this.eventsSelectedForMultiAction.selectedEventIds.indexOf(
              event.testEventId
            ) >= 0
        )
        .filter(
          ({ numberOfBookings }) => numberOfBookings > 0
        )
        .map(({ testEventId }) => testEventId);

    const send = await this.notificationService.confirm({
      title:
        'message.send-test-invitations.confirmation.title',
      message:
        'message.send-test-invitations.confirmation.message',
      translationParams: {
        selectedEventsWithBookings:
          selectedEventsWithBookings.join(', '),
      },
    });
    if (send) {
      await this.examEventService.sendInvitationsForSelectedEvents(
        this.item.id,
        selectedEventsWithBookings
      );

      await this.notificationService.success({
        title:
          'message.send-test-invitations.success.title',
        message:
          'message.send-test-invitations.success.message',
        translationParams: {
          selectedEventsWithBookings:
            selectedEventsWithBookings.join(', '),
        },
      });
    }
  }

  async uploadTestResults(
    droppedFiles: NgxFileDropEntry[]
  ): Promise<void> {
    if (droppedFiles.length === 0) {
      await this.notificationService.error(
        'error.result-upload-failed.no-files'
      );
    } else if (droppedFiles.length > 1) {
      await this.notificationService.error(
        'error.result-upload-failed.too-many-files'
      );
    } else {
      const { fileEntry, relativePath } = droppedFiles[0];
      if (!fileEntry.isFile) {
        this.notificationService.error(
          'error.result-upload-failed.not-a-csv-file'
        );
        return;
      }
      try {
        const uploadResult = await this.uploadFileEntry(
          fileEntry as FileSystemFileEntry,
          relativePath
        );
        if (uploadResult.errorMessage) {
          await this.notificationService.error(
            'error.result-upload-failed.errors-detected-in-csv'
          );
          console.table(uploadResult.errors);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        await this.notificationService.error(
          'error.result-upload-failed.unknown-error'
        );
      }
    }
  }

  async downloadTestResults(): Promise<void> {
    try {
      const response =
        await this.examEventService.downloadTestResultsList(
          this.item.id
        );

      if (!response.body) {
        throw new Error('No body present in csv download');
      }

      const fileName =
        this.fileNameFromContentDispositionHeader(
          response,
          'test-results'
        );

      // Adding a UTF-8 BOM, as explained in https://stackoverflow.com/a/41363077/2382246
      // saveAs does allow setting "autoBom: true" in the options. However, this doesn't seem to work in this case, so we add the BOM ourselves.
      const blob = new Blob(
        [new Uint8Array([0xef, 0xbb, 0xbf]), response.body],
        { type: response.body?.type }
      );

      saveAs(blob, fileName);
    } catch (error) {
      this.logger.error(error);
      await this.notificationService.error(
        'error.csv-download-failed'
      );
    }
  }

  async generateAndSendOutReports(): Promise<void> {
    try {
      const response =
        await this.examEventService.generateAndSendOutReports(
          this.item.id
        );

      if (response.status >= 200 && response.status < 300) {
        await this.notificationService.success({
          message:
            'message.test.report-generation-triggered',
        });
      } else {
        await this.notificationService.error(
          'error.test-result-generation-failed'
        );
      }
    } catch (error) {
      this.logger.error(error);
      await this.notificationService.error(
        'error.test-result-generation-failed'
      );
    }
  }

  private uploadFileEntry(
    fileEntry: FileSystemFileEntry,
    relativePath: string
  ): Promise<TestResultsUploadInfo> {
    return fileEntry.file(async (file: File) => {
      // Skip files with size 0 or files that are hidden in unixoid OSes.
      // We can't skip hidden files on Windows, since we don't seem to be able to identify those as hidden.
      if (file.size === 0 || file.name.startsWith('.')) {
        throw new Error(
          `Cannot upload the empty or hidden file '${fileEntry.name}'.`
        );
      }

      let fileTypeIsCsv =
        file.type === 'text/csv' ||
        file.type.startsWith('text/csv;');
      if (!fileTypeIsCsv) {
        // According to https://christianwood.net/posts/csv-file-upload-validation/, CSV files created under Windows may
        // have one of the following non 'text/csv' mime types that we have to check for...
        const possibleAlternativeWindowsCsvMimeTypes = [
          'text/plain',
          'text/x-csv',
          'application/vnd.ms-excel',
          'application/csv',
          'application/x-csv',
          'text/comma-separated-values',
          'text/x-comma-separated-values',
          'text/tab-separated-values',
        ];
        const fileHasAPossibleAlternativeMimeType =
          possibleAlternativeWindowsCsvMimeTypes.find(
            (possibleAlternativeMimeType) =>
              file.type === possibleAlternativeMimeType ||
              file.type.startsWith(
                possibleAlternativeMimeType + ';'
              )
          );
        if (fileHasAPossibleAlternativeMimeType) {
          fileTypeIsCsv =
            file.name.endsWith('.csv') ||
            file.name.endsWith('.ssv');
        }
      }
      if (!fileTypeIsCsv) {
        throw new Error(
          `The file '${fileEntry.name}' is not a CSV file. The actual type is '${file.type}'.`
        );
      }

      // Mark that this file is currently being uploaded
      this.currentlyUploadingResults = true;

      try {
        return this.examService.uploadResultCsv(
          this.item.id,
          file,
          relativePath
        );
      } finally {
        // Mark that the upload of the file has been completed
        this.currentlyUploadingResults = false;
      }
    });
  }
}
